/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/avatar.png/" }) {
        childImageSharp {
          fixed(width: 80, height: 80, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            github
            instagram
            youtube
            linkedin
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  const avatar = data?.avatar?.childImageSharp?.fixed

  return (
    <div className="bio">
      {avatar && (
        <Image
          fixed={avatar}
          alt={author?.name || ``}
          className="bio-avatar"
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      )}
      {author?.name && (
        <p>
          Some thought-pickings by <strong>{author.name}</strong>,{" "}
          {author?.summary || null}
          {` `}
          <hr></hr>
          <span className="social-icon-background">
            <img
              className="social-icon"
              width="15"
              height="15"
              src="/icons/twitter.svg"
            />
          </span>{" "}
          <a href={`https://twitter.com/${social?.twitter || ``}`}>Twitter</a>
          <br />
          <img
            className="social-icon"
            width="15"
            height="15"
            src="/icons/github.svg"
          />{" "}
          <a href={`https://github.com/${social?.github || ``}`}>GitHub</a>
          <br />
          <img
            className="social-icon"
            width="15"
            height="15"
            src="/icons/linkedin.svg"
          />{" "}
          <a href={`https://linkedin.com/in/${social?.linkedin || ``}`}>
            LinkedIn
          </a>
          <br />
          <img
            className="social-icon"
            width="15"
            height="15"
            src="/icons/youtube.svg"
          />{" "}
          <a href={`https://youtube.com/channel/${social?.youtube || ``}`}>
            Youtube
          </a>
          <br />
          <img
            className="social-icon"
            width="15"
            height="15"
            src="/icons/instagram.svg"
          />{" "}
          <a href={`https://instagram.com/${social?.instagram || ``}`}>
            Instagram
          </a>
          <br />
          <br />
          <a
            className="projectButton"
            href={`https://github.com/${social?.github || ``}`}
          >
            View My Projects
          </a>
          <br />
          <br />
          <a href={`https://demos.matasr.com`}>Explore my demos.</a>
        </p>
      )}
    </div>
  )
}

export default Bio
